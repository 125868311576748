/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
  font-family: "CocoRegular";
  src: url("./fonts/manrope-light.otf") format("opentype");
}

@font-face {
  font-family: "MicroExtend";
  src: url("./fonts/02587_ARIALMT.ttf") format("opentype");
}

h1 {
  position: fixed;
  margin-top: 0;
  font-family: "CocoRegular", Arial, sans-serif;
  font-size: 7.5vw; /* Past de grootte aan op basis van de breedte van het scherm */
  font-weight: 100;
  left: 50%;
  transform: translateX(-50%);
  width: 96vw;
  top: 16vh;
}

p {
  font-family: "MicroExtend", Arial, sans-serif;
  font-size: 5vw; /* Past de grootte aan op basis van de breedte van het scherm */
  line-height: 130%;
}

html,
body {
  height: 100%; /* Zorgt ervoor dat beide elementen minimaal de volledige hoogte van het venster innemen */
  left: 0;
  right: 0;
  margin: 0; /* Verwijdert de standaardmarge */
  padding: 0; /* Verwijdert de standaardpadding */
}

body {
  font-family: Arial, sans-serif;
  color: white;
  text-align: center;
  background-color: #0775d6; /* Donkerblauwe achtergrondkleur */
  background-image: url("./img/Screens-AI-Juice-Bar-background.webp"); /* Achtergrondafbeelding */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.container {
  padding-top: 15vh;
}

.start-button {
  position: fixed; /* Gebruik fixed in plaats van absolute voor positie t.o.v. het viewport */
  left: 50%; /* Start de knop in het midden van het scherm */
  transform: translateX(
    -50%
  ); /* Trek de helft van de breedte van de knop af om het perfect te centreren */
  top: 88vh; /* Afstand van de onderkant van het scherm */
  background-color: #34c222;
  color: black;
  border: none;
  padding: 3vw 6vw; /* Gebruik vw eenheden voor padding */
  font-size: 3.5vw; /* Past de grootte aan op basis van de breedte van het scherm */
  cursor: pointer;
  border-radius: 5px;
}

.start-button:hover {
  background-color: #ffa500; /* Lichter oranje voor hover effect */
}

#logo {
  position: fixed;
  left: 20px;
  top: 3vh; /* 5% van de top van de viewport */
  width: 30vw; /* 15% van de viewport breedte */
}

img[alt="EmptyGlass"] {
  position: fixed;
  top: 45vh;
  left: 50%;
  transform: translateX(
    -50%
  ); /* Trek de helft van de breedte af om het perfect te centreren */
  height: 31vh; /* Maakt het plaatje 30% van de hoogte van het scherm */
}

#first-paragraph {
  position: fixed;
  top: 28vh;
  left: 50%;
  transform: translateX(-50%);
  width: 60vw;
}

#second-paragraph {
  display: none;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 60vw;
  top: 14vh;
}

#third-paragraph {
  position: fixed;
  top: 76vh;
  left: 50%;
  transform: translateX(-50%);
  width: 90vw;
}

#page2-second-paragraph {
  position: fixed;
  top: 28vh;
  left: 50%;
  transform: translateX(-50%);
  width: 60vw;
}

#page2-first-paragraph {
  top: 14vh;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 60vw;
}

#page2-ready-text {
  position: fixed;
  top: 78vh;
  left: 50%;
  transform: translateX(-50%);
  width: 90vw;
}

#page2-enjoy-text {
  position: fixed;
  top: 88vh;
  left: 50%;
  transform: translateX(-50%);
  width: 90vw;
}

.result-images {
  width: 25vw;
}

.result-text {
  font-size: 24pt;
}

.result-row {
  display: flex;
  /* justify-content: space-around; */
  position: fixed;
  left: 2vw;
  right: 2vw;
  top: 15vh;
  /* justify-content: space-between; */
}

.result-row2 {
  display: flex;
  position: fixed;
  left: 2vw;
  right: 2vw;
  top: 25vh;
  /* justify-content: space-between; */
}

.result-column {
  width: 20vw;
  flex: 1;
  /* text-align: left; */
}

.card-quarter-high {
  z-index: 2;
  background-color: #fff;
  border-radius: 3px;
  width: 46vw;
  height: 55vh;
  padding: 1vw;
  margin-top: 1vw;
  margin-left: 1vw;
}

.total-drinks-per-drinktype-card {
  z-index: 2;
  background-color: #fff;
  border-radius: 3px;
  width: 46vw;
  height: 55vh;
  padding: 1vw;
  margin-top: 1vw;
  margin-left: 1vw;
}

.card-quarter-low {
  z-index: 2;
  background-color: #fff;
  border-radius: 3px;
  width: 46vw;
  height: 32vh;
  padding: 1vw;
  margin-top: 1vw;
  margin-left: 1vw;
}

.card-title {
  margin-top: 0px;
  color: #0775d6;
  text-align: left;
  font-family: Arial, sans-serif;
  font-size: 1.2vw; /* Past de grootte aan op basis van de breedte van het scherm */
  line-height: 100%;
}

.charts-bottom-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Dit zorgt ervoor dat de ruimte tussen de kaarten gelijkmatig wordt verdeeld */
  height: 40vh;
}

.charts-top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Dit zorgt ervoor dat de ruimte tussen de kaarten gelijkmatig wordt verdeeld */
  height: 55vh;
}

.results-screen {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  padding: 1vw;
  background-color: #dddddd;
}

.total-drinks-text {
  margin-top: 4vh;
  color: #999999;
  font-family: "MicroExtend", Arial, sans-serif;
  font-size: 2vw; /* Past de grootte aan op basis van de breedte van het scherm */
  line-height: 130%;
}

.result-score {
  position: fixed;
  left: 50%;
  font-size: 3.5vw;
  transform: translate(
    -50%,
    -50%
  ); /* Centreert het element zowel horizontaal als verticaal */
  text-align: center; /* Zorgt ervoor dat de tekst binnen het element gecentreerd is */
}

.perfect-juice-text {
  margin-top: 2vh;
  color: #0775d6;
  font-family: "MicroExtend", Arial, sans-serif;
  font-size: 2vw; /* Past de grootte aan op basis van de breedte van het scherm */
  line-height: 130%;
}

.company-logo-result-page {
  max-width: 100%; /* Zorgt ervoor dat de breedte van het logo zich aanpast aan de container */
  max-height: 100%; /* Zorgt ervoor dat de hoogte van het logo zich aanpast aan de container */
  object-fit: contain; /* Zorgt ervoor dat het gehele logo zichtbaar is binnen de container */
}

.row1-col1 {
  z-index: 2;
  background-color: #fff;
  border-radius: 3px;
  flex: 4;
  padding: 1vw;
  margin-top: 1vw;
  margin-left: 1vw;
}

.row1-col2 {
  flex: 1;
  background-color: #fff;
  border-radius: 3px;
  padding: 1vw;
  margin-top: 1vw;
  margin-left: 1vw;
}

.row1-col3 {
  flex: 3;
  /* padding: 1vw; */
  margin-top: 1vw;
  margin-left: 1vw;
  /* background-color: #0775d6; */
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  row-gap: 1vw;
  align-items: stretch;
  padding-bottom: 2vw;
}

.row1-col3-element {
  /* display: block; */
  flex-grow: 0; /* Voorkomt dat het element groter wordt */
  flex-shrink: 0; /* Voorkomt dat het element kleiner wordt */
  flex-basis: 33%; /* Je kunt dit wijzigen in een specifieke waarde als dat nodig is */
  align-self: auto;
  background-color: #fff;
  border-radius: 3px;

  display: flex;
  justify-content: center; /* Centreert het logo horizontaal */
  align-items: center; /* Centreert het logo verticaal */
  overflow: hidden; /* Voorkomt dat het logo buiten het element treedt */
}

.row1-col3-element3 {
  display: block;
  flex-grow: 0; /* Voorkomt dat het element groter wordt */
  flex-shrink: 0; /* Voorkomt dat het element kleiner wordt */
  flex-basis: 33%;
  align-self: auto;
  /* background-color: #fff; */
  border-radius: 3px;
  /* margin-bottom: 1vh; */
  padding: 0px;
  /* padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px; */
  display: flex;
  flex-direction: row;
  /* padding-bottom: 1vh; */
  column-gap: 1vh;
}

.row2-col1 {
  z-index: 2;
  background-color: #fff;
  border-radius: 3px;
  flex: 4;
  padding: 1vw;
  margin-top: 1vw;
  margin-left: 1vw;
}

.row2-col2 {
  flex: 4;
  background-color: #fff;
  border-radius: 3px;
  padding: 1vw;
  margin-top: 1vw;
  margin-left: 1vw;
}

.content-card {
  /* padding: 1vw; */
  /* margin-right: 10px; */
  background-color: #fff;
  overflow: auto;
  border-radius: 3px;
  flex: 1;
  padding: 1vw;
}

.text-black {
  color: #000;
}

.start-button {
  text-decoration: none;
  color: #fff;
  background-color: #34c222;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 88vh;
  padding: 3vw 6vw;
  font-size: 3.5vw;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 400;
}

@media (orientation: landscape) {
  .title-text {
    font-size: 4vw;
  }
  #first-paragraph {
    font-size: 1.5vw;
  }
  #second-paragraph {
    font-size: 1.5vw;
  }
  #third-paragraph {
    font-size: 1.5vw;
  }
  .start-button {
    padding: 1.5vw 3vw;
    font-size: 1.5vw;
  }
  #page2-first-paragraph {
    font-size: 1.5vw;
  }
  #page2-second-paragraph {
    font-size: 1.5vw;
  }
  #page2-ready-text {
    font-size: 1.5vw;
  }
  #page2-enjoy-text {
    font-size: 1.5vw;
  }
  .result-score {
    font-size: 0.5vw;
  }
}
