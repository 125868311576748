.user-page-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Dit zorgt ervoor dat de ruimte tussen de kaarten gelijkmatig wordt verdeeld */
  align-items: center;
  height: 55vh;
  column-gap: 2vw;
}

.user-page-row-col {
  z-index: 2;
  /* background-color: #fff; */
  border-radius: 3px;
  flex: 1;
  padding: 1vw;
  margin-top: 1vw;
  margin-left: 1vw;
  max-width: 80vw;
  min-width: 40vw;
  /* max-height: 40vh; */
  /* min-height: 300px; */
}

.button-3 {
  appearance: none;
  background-color: #2ea44f;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 1vw;
  font-weight: 500;
  line-height: 1.1vw;
  padding: 0.5vw 1vw;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  width: 15vw;
  /* margin-left: 1vw; */
}

.button-3:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-3:hover {
  background-color: #2c974b;
}

.button-3:focus {
  box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
  outline: none;
}

.button-3:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, 0.1);
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
}

.button-3:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
}

.formbold-mb-5 {
  margin-bottom: 2vw;
}
.formbold-pt-3 {
  padding-top: 1vw;
}
.formbold-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 0;
}

.formbold-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.5vh;
  margin: 0 auto;
  /* max-width: 60vw; */
  width: 100%;
  /* background: white; */
}
.formbold-form-label {
  display: block;
  font-weight: 500;
  font-size: 1vw;
  color: #07074d;
  margin-bottom: 1vh;
}
.formbold-form-label-2 {
  font-weight: 600;
  font-size: 1vw;
  margin-bottom: 2vh;
}

.formbold-form-input {
  width: 100%;
  padding: 1vw 2vw;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  background: white;
  font-weight: 500;
  font-size: 1vw;
  color: #6b7280;
  outline: none;
  resize: none;
}
.formbold-form-input:focus {
  border-color: #6a64f1;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold-btn {
  text-align: center;
  font-size: 1vw;
  border-radius: 6px;
  padding: 1vw 3vw;
  border: none;
  font-weight: 600;
  background-color: #6a64f1;
  color: white;
  cursor: pointer;
}
.formbold-btn:hover {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
}

.formbold--mx-3 {
  margin-left: -1vw;
  margin-right: -1vw;
}
.formbold-px-3 {
  padding-left: 1vw;
  padding-right: 1vw;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}

.formbold-file-input input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  color: #000;
}

button:disabled,
button[disabled] {
  /* border: 1px solid #999999; */
  background-color: #cccccc;
  color: #666666;
}

.formbold-file-input label {
  position: relative;
  border: 1px dashed #e0e0e0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4vw;
  text-align: center;
}
.formbold-drop-file {
  display: block;
  font-weight: 600;
  color: #07074d;
  font-size: 1vw;
  margin-bottom: 8px;
}

.formbold-or {
  font-weight: 500;
  font-size: 1vw;
  color: #6b7280;
  display: block;
  margin-bottom: 8px;
}
.formbold-browse {
  font-weight: 500;
  font-size: 1vw;
  color: #07074d;
  display: inline-block;
  padding: 8px 28px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.formbold-file-list {
  border-radius: 6px;
  background: #f5f7fb;
  padding: 2vw 6vw;
}

.formbold-file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formbold-file-item button {
  color: #07074d;
  border: none;
  background: transparent;
  cursor: pointer;
}

.file-name {
  margin-top: 2vh;
  font-weight: 500;
  font-size: 1vw;
  color: #07074d;
  padding-right: 12px;
}
.formbold-progress-bar {
  margin-top: 20px;
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 8px;
  background: #e2e5ef;
}

.formbold-progress {
  position: absolute;
  width: 75%;
  height: 100%;
  left: 0;
  top: 0;
  background: #6a64f1;
  border-radius: 8px;
}

@media (min-width: 540px) {
  .sm\:w-half {
    width: 50%;
  }
  .user-page-logo {
    z-index: 20;
    /* width: 10vw; */
    max-width: 6vw;
  }
}

@media all and (orientation: portrait) {
  .user-page-logo {
    z-index: 20;
    /* width: 10vw; */
    max-width: 10vw;
  }

  .user-page-row-col {
    width: 60vw;
    /* max-height: 40vh; */
    /* min-height: 300px; */
  }

  /* .formbold-form-wrapper {
    width: 40%;
  } */

  .formbold-browse {
    width: 15%;
    min-width: none;
  }

  .button-wrapper {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 25%;
    /* padding: 4vw; */
  }
}

.delete {
  background-color: #d10000;
}

@media (orientation: landscape) {
  .formbold-form-wrapper {
    row-gap: 1.5vh;
  }
}

/* styles for user page in portrait mode */

@media (orientation: portrait) {
  .button-3 {
    font-size: 1vh;
    line-height: 1.1vh;
    padding: 0.5vh 1vh;
    width: 20vw;
  }

  .formbold-btn {
    font-size: 1vh;
    line-height: 1.1vh;
    padding: 0.5vh 1vh;
    width: 15vw;
  }

  .user-page-row-col {
    width: 90vw;
    height: 30vh;
  }
  .formbold-form-label {
    font-size: 1vh;
  }
}
