.login-card {
  width: 300px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin: auto;
  margin-top: 50px;
}

.login-card form {
  display: flex;
  flex-direction: column;
}

.login-card label {
  margin-bottom: 10px;
  color: #000;
}

.login-card input[type="text"],
.login-card input[type="password"] {
  padding: 10px;
  margin-top: 5px;
  margin-left: 1vw;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.login-card button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-card button:hover {
  background-color: #0056b3;
}
